import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometer, faExchange, faUsers, faLock, faBook, faBriefcase, faDollar, faWrench, faBolt, faUserCircle, faSignOut, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import AppContext from '../AppContext';
import { userHasActions } from '../helpers/UserActionsHelper';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Badge, IconButton } from '@material-ui/core';
const actionsList = require('../helpers/ActionsList.json');

const { Sider, Content } = Layout;

function RouterMenu(props) {
    const context = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [screenPermisions, setScreenPermisions] = useState([]);
    const [menu, setMenu] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const systemDate = useState(localStorage.getItem('SystemDate'));
    const [menuLoaded, setMenuLoaded] = useState(false);
    const [events, setEvents] = useState(0);


    useEffect(() => {
          const loadMenu = async () => {
            const response = await fetch(window.REACT_APP_HOME_URL + 'menu/menu.json');
            let systemDateMenu = [];
            systemDateMenu = [{
                    Label: systemDate,
                    Icon: "fa-calendar",
                    Route: "",
                    HasChildren: false,
                    ComponentName: "",
                    Events: props.events
                },
                {
                    "Separator": true
                    }
                ]
            setEvents(props.events);
            let menu = await response.json();
            let allMenu = [...systemDateMenu, ...menu];
            setMenu(allMenu);
            setMenuLoaded(true);
          };
          if((systemDate && !menuLoaded)||(props.events > 0 && events !== props.events))
          {
            loadMenu();
          }
    }, [systemDate, props.events]);

    useEffect(() => {
        const loadActions = async () => {
            let _actions = JSON.parse(localStorage.getItem('ScreenShowPermissions'));
            if (_actions == null) {
                _actions = await userHasActions(actionsList.actions);
            }
            setScreenPermisions(_actions);
        };
        loadActions();
    }, []);

    const getIcon = (icon) => {
        switch (icon) {
            case 'fa-tachometer':
                return <FontAwesomeIcon icon={faTachometer} />;
            case 'fa-exchange':
                return <FontAwesomeIcon icon={faExchange} />;
            case 'fa-users':
                return <FontAwesomeIcon icon={faUsers} />;
            case 'fa-lock':
                return <FontAwesomeIcon icon={faLock} />;
            case 'fa-book':
                return <FontAwesomeIcon icon={faBook} />;
            case 'fa-briefcase':
                return <FontAwesomeIcon icon={faBriefcase} />;
            case 'fa-dollar':
                return <FontAwesomeIcon icon={faDollar} />;
            case 'fa-wrench':
                return <FontAwesomeIcon icon={faWrench} />;
            case 'fa-bolt':
                return <FontAwesomeIcon icon={faBolt} />;
            case 'fa-user-circle':
                return <FontAwesomeIcon icon={faUserCircle} />;
            case 'fa-sign-out':
                return <FontAwesomeIcon icon={faSignOut} />;
            case 'fa-calendar':
                return <FontAwesomeIcon icon={faCalendar} />;
            default:
                return null; // no icon
        }
    };

    const additionalItems = [
        {
            key: 'my-account',
            label: 'Change Password',
            icon: getIcon('fa-user-circle')
        },
        {
            key: 'logout',
            label: 'Log out',
            icon: getIcon('fa-sign-out')
        }
    ];

    const renderLabel = (item, route = '') => {
        if (item.HasChildren || item.Submenu) {
            return item.Label;
        }
        if(item.Events > 0)
        {
            return <><Link to={`${route}`} style={{marginRight:'10px'}}>{item.Label}</Link> 
               
                <Badge badgeContent={item.Events} color='primary'>
                <NotificationsActiveIcon  onClick={props.onEventsClick}/>
                </Badge></>
        }
        return <Link to={`${route}`}>{item.Label}</Link>;
    };

    const hasAction = (item) => {
        if (item.Separator || item.Submenu) {
            return true; // Don't check for screen permitions
        }
        if (screenPermisions == null) {
            return false;
        }
        return screenPermisions[item.UserAction];
    };

    const emptySections = (item) => {
        if (item.children) {
            item.children = item.children.filter(emptySections);
            return (item.children.length > 0);
        }
        return true;
    };

    const transformItems = (item, route = '', index = '0') => {
        if (item.Separator) {
            return {
                key: `${route}/separator${index}`,
                type: 'divider'
            };
        }
        let _route = `/${route}/${item.Route}`;
        if (item.Submenu) {
            _route = `${route}/submenu${index}`;
            // Keep passing the parent route to childrens
            item.Route = route;
        } else if (route === '') {
            _route = `${route}/${item.Route}`;
        }

        return {
            key: _route,
            // title: item.Label,
            label: renderLabel(item, _route),
            icon: getIcon(item.Icon),
            children: item.Children != null ? item.Children.filter(hasAction).map((childItem, _index) => transformItems(childItem, item.Route, index + _index)) : null
        };
    };

    const items = [...menu.map((item) => transformItems(item)), ...additionalItems].filter(emptySections);

    const onMenuClick = (e) => {
        if (e.key === 'my-account') {
            context.toggleShowChangePassword();
        } else if (e.key === 'logout') {
            navigate('/');
            context.forceLogout();
        }
    }

    const getBasePath = (pathName) => {
        const ocurrences = (pathName.match(/\//g) || []).length;
        switch (ocurrences) {
            case 0: return [];
            case 1: return [];
            default: return [pathName.substring(0, pathName.indexOf('/', 1))];
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme='dark'>
                <Menu mode="inline" theme='dark' items={items} selectable={false} onClick={onMenuClick} defaultOpenKeys={getBasePath(location.pathname)} selectedKeys={[location.pathname]} />
            </Sider>
            <Layout style={{ backgroundColor: '#ffffff' }} >
                <Content style={{ margin: '5px 16px', minHeight: 280, backgroundColor: '#ffffff' }}>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default RouterMenu;
