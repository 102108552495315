import jwt from 'jsonwebtoken';
//import aesjs from 'aes-js';
import publicIp from "public-ip";

var CryptoJS = require("crypto-js");


//const apiURL = process.env.REACT_APP_API_URL;
const encryptKey = process.env.REACT_APP_ENCRYPTION_KEY;
const useEncryption = window.REACT_APP_USE_NEW_ENCRYPT;
const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;
const apidisabledataencriptation = window.REACT_APP_APIDISABLEDATAENCRIPTATION;

export async function decrypt(cypher){

    if (apidisabledataencriptation) {
        console.log(cypher.data)
        return JSON.parse(cypher.data);
    }

    let responseJSON;
    if(useEncryption){
        var cipher = cypher.data;

        var key = CryptoJS.enc.Utf8.parse(encryptKey);     // Use Utf8-Encoder. 
        var iv  = CryptoJS.enc.Utf8.parse('');                     // Use Utf8-Encoder

        var ciphertext = CryptoJS.enc.Base64.parse(cipher);    // Use Base64-Encoder.       
        var encryptedCP = CryptoJS.lib.CipherParams.create({
            ciphertext: ciphertext,
            formatter: CryptoJS.format.OpenSSL                                     // Optional, but required for encryptedCP.toString() 
        });
        var decryptedWA = CryptoJS.AES.decrypt(encryptedCP, key, { iv: iv});
        var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8); 
        responseJSON = await JSON.parse(decryptedUtf8);

    } else {
       var decoded = jwt.decode(cypher.data);
       responseJSON = decoded.response;
    }

    return responseJSON;
}

export async function login(username,password) {
    try {
        let publicIP = await publicIp.v4();
        let datePC=new Date();     
        let userRequest = {
            UserName: username,
            Password: password,
            PublicIP: publicIP,
            datePC: datePC
        }
        let response = await fetch(apiURL + 'Login/Post',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,
            },
            body: JSON.stringify(userRequest),
        });
        if (response.status === 401 || response.status === 406 || response.status === 412 || response.status === 403) 
        {
            if (response.status === 401)
            {
                let responseJson = await response.json();
                responseJson = JSON.parse(responseJson.response);
                responseJson.status = response.status;
                return responseJson;
            }
            if(response.status === 403)
            {
                let responseJson = await response.json();
                return responseJson;
            }
            return response;
        }
        var responseJSON;
        let responseJWT = await response.json();
        responseJSON = await decrypt(responseJWT);

        if(response.status === 200) {
            localStorage.setItem('SessionID', responseJSON.SessionID);
            localStorage.setItem('UserID', responseJSON.UserId);
            localStorage.setItem('UserName', username);
            //localStorage.setItem('AlreadySessionCreated', true);
            localStorage.setItem('SessionCreatedOn', Date.now());
            localStorage.setItem('PublicIP', publicIP);
            localStorage.setItem('HasComplianceAccess', responseJSON.HasComplianceAccess);
            localStorage.setItem('UserEmail', responseJSON.UserEmail);
            localStorage.setItem('MobileNumber', responseJSON.MobileNumber);

        }
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function logout() {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + 'Logout/Post', {
            method: 'POST',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        localStorage.removeItem('AlreadySessionCreated');
        console.log(responseJson);
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function getServerVersion() {
    //let response = await genericGetWithParametersNoEncrypted("Version/Get",{})
    let responseWeb = await fetch(apiURL + 'Version/Get', {
            method: 'GET'
        });
    let response = await responseWeb.json();
    response.httpStatusCode = responseWeb.status;
    return response;  
}

export async function refreshVersionLogin() {
    let responseWeb = await fetch(apiURL + 'Version/GetRefreshVersionLogin?App=Desktop', {
            method: 'GET'
        });
    let response = await responseWeb.json();
    response.httpStatusCode = responseWeb.status;
    return response;  
}

export async function refreshVersionLogout() {
    let responseWeb = await fetch(apiURL + 'Version/GetRefreshVersionLogout?App=Desktop', {
            method: 'GET'
        });
    let response = await responseWeb.json();
    response.httpStatusCode = responseWeb.status;
    return response;  
}

export async function changePassword(model){
    var method = 'POST';
    var apiName = 'User/ChangePassword';
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}

export async function newSearchCustomers(search,page,items){
    try {
        let params = {
            'TextToSearch': search,
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('CustomerSearch/GetJson',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function searchCustomersSalesforce(accountId){
    try {
        let params = {
            'accountId': accountId
        }
        let response = await genericGetWithParameters('CustomerSearch/SearchCustomersSalesforce',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getLookUpTable(name) {
    try {
        let params = {
            LookUpTablesDescription: name,
        }
        let response = await genericGetWithParameters('LookUpTable/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getLookUpTableByFilter(name,field4) {
    try {
        let params = {
            LookUpTablesDescription: name,
            field4: field4
        }
        let response = await genericGetWithParameters('LookUpTable/GetByFilter', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParameters(apiName, params){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = ''

        if(params)
        {
            query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        }
        
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var responseJSON = {};
        if(responseJWT.data !== undefined){
            responseJSON = await decrypt(responseJWT);
        } 
        if(responseJWT.Message !== undefined){
            httpErrorMessage = responseJWT.Message;
        }
        responseJSON.httpStatusCode = response.status;
        responseJSON.httpErrorMessage = httpErrorMessage;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithParametersNoEncrypted(apiName, params){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = ''

        if(params !== null)
        {
            query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        }
        
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'PUT',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJWT = await response.json();
        responseJWT.httpStatusCode = response.status;
        return responseJWT;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithOutBody(apiName, params){
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'POST',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericDeleteWithParameters(apiName, params){
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'DELETE',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericDeleteWithBody(apiName, model){
  try {
      let sessionID = await localStorage.getItem('SessionID');
      let response = await fetch(apiURL + apiName , {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'SessionID': sessionID,
            'APIKey': apiKey,
          },
          body: JSON.stringify(model),
      });
      let responseJson = await response.json();
      responseJson.httpStatusCode = response.status;
      return responseJson;
  } catch (error) {
      console.error(error);
  }
}

export async function genericCallWithBody(method, apiName, model){
    try {
        let sessionID = await localStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        //console.log(responseJson);
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        responseJson.httpErrorMessage = httpErrorMessage;
        return responseJson;
        /*if(response.status === 200 ||
            response.status === 400){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.httpStatusCode = response.status;
            return responseJSON;
        }else{
            return response;
        }*/
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParametersNoEncrypted(apiName, params){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = '';
        if(params !== {}){
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        } 
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        let jsonResponse = await response.json();
        jsonResponse.httpStatusCode = response.status;
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        jsonResponse.httpErrorMessage = httpErrorMessage;
        return jsonResponse;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParametersNoEncryptedAndNoJsonConvert(apiName, params){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = '';
        if(params !== {}){
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        } 
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
        });
        
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateSession(){
    try {

    let date = new Date();
        
        const params = {
            'personID': localStorage.getItem('UserID'),
            'clientDateTime': `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:00`
        };
        let response = await genericGetWithParameters('Session/Get',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBodyAndResponseDecoded(method, apiName, model){
    try {
        let sessionID = await localStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        let responseJWT = await response.json();
        let responseJSON = await decrypt(responseJWT);
        responseJSON.httpStatusCode = response.status;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithBody(apiName, model){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let response = await fetch(apiURL + apiName, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(model),
        });
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 200:
                break;
            case 300:
                httpErrorMessage = 'Duplicated user name.';
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        let responseJWT = await response.json();
        //console.log(responseJWT.data);
        var responseJSON = {};
        if(responseJWT.data !== undefined){
            responseJSON = await decrypt(responseJWT);
        } 
        if(responseJWT.Message !== undefined){
            httpErrorMessage = responseJWT.Message;
        }
        responseJSON.httpStatusCode = response.status;
        responseJSON.httpErrorMessage = httpErrorMessage;
        return responseJSON;
    } catch (error) {
        console.error(error);
    }
}

export const generateSMSCode = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'MobileValidation/GenerateCode', model);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const validateSMSCode = async (model) => {
    try {
        const response = await genericCallWithBody('POST', 'MobileValidation/ValidateCode', model);
        return response;
    } catch (error) {
        console.error(error);
    }
};