import React from 'react';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import { deleteBankAccount, getBankAccountsByPerson } from '../../../../networking/NetworkingBankAccounts';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import BankAccountScreen from '../directDebitAccounts/BankAccountScreen';

var style = getStyle();

export default class BankAccountsScreen extends React.Component {
    state = {
        showAlert               : false,
        alertTitle              : '',
        alertMessage            : '',
        loading                 : false,
        newSearch               : false,
        bankAccounts            : [],
        selectedBankAccount     : null,
        showBankAccount         : false,
        showDeleteAlert         : false,
        showSuccessDeleteAlert  : false,
        readyToDeleteBankAccount: {},
        pages                   : 1,
        totalPages              : 0
    }

    componentDidMount() {
        this.updatePage();
    }

    handleBackLink = () =>
        this.setState({
            showBankAccount: false
        });

    handleAddClick = () =>
        this.setState({
            selectedBankAccount: null,
            showBankAccount: true
        }, () => {if(this.props.createBankAccount){this.props.createBankAccount()}});

    handleDeleteClick = (obj) =>
        this.setState({
            showDeleteAlert         : true,
            readyToDeleteBankAccount: obj});

    handleRowClick = (row) => {
        var title = 'Add / Update Bank Account ' + row["Account ID"];
        this.setState({
            selectedBankAccount: {
                BankAccountID: row["Account ID"],
                Currency: row.Currency,
                AccountNumber: row["Account Number"],
                CountryIdName: row.Country
            },
            showBankAccount: true
        }, () => {
            if (this.props.updateBankAccount != null) {
                this.props.updateBankAccount(title)
            }
        });
    }

    closeAlert = () =>
        this.setState({
            showAlert: false,
            alertMessage: '',
            alertTitle: ''
        });

    closeDeleteAlert = () =>
        this.setState({
            showDeleteAlert         : false,
            readyToDeleteBankAccount: {}
        });

    openDeleteAlert = () =>
        this.setState({
            showDeleteAlert: true
        });

    okSuccessAlert = () =>
        this.setState({
            showSuccessDeleteAlert: false
        });

    yesDeleteAlert = async () => 
    {
        this.setState({ showDeleteAlert: false, loading: true });
        const deleteResponse = await deleteBankAccount(this.state.readyToDeleteBankAccount["Account ID"]);
        
        let auxTitle = 'Error';
        let auxMessage = 'An error occurred while trying to delete the bank account. Please try again later.';
        let deleted = false;

        if(deleteResponse !== undefined)
        {
            switch (deleteResponse.httpStatusCode) 
            {
                case 200:
                {
                    deleted = true;
                    break;
                }
                case 412:
                case 503:
                {
                    if(deleteResponse.Message !== undefined && deleteResponse.Message !== null && deleteResponse.Message !== '')
                    {
                        auxMessage = deleteResponse.Message;
                    }
                    break;
                }
                default:
                {
                    break;
                }
            }

            if(deleted)
            {
                this.setState({ 
                    showSuccessDeleteAlert  : true, 
                    loading                 : false
                }, () =>{
                    this.updatePage();
                });
            }else
            {
                this.setState({
                    showAlert   : true,
                    alertTitle  : auxTitle,
                    alertMessage: auxMessage,
                    loading     : false
                });
            }
        }else
        {
            this.setState({
                showAlert   : true,
                alertTitle  : auxTitle,
                alertMessage: auxMessage,
                loading     : false
            });
        }
    }

    changePage = (value) => {
        this.setState({
            pages: value,
            newSearch: false
        });
    }

    updatePage = async () => 
    {
        this.setState({loading: true})
        const json = await getBankAccountsByPerson(this.props.data);
        if (json !== undefined && json.bankAccounts !== undefined) 
        {
            const bankAccounts = json.bankAccounts.filter(bankAccount => bankAccount.Currency !== null && bankAccount.AccountType === "Actual / Local");
            const totalCount = bankAccounts.length;
            const totalPages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            this.setState({
                bankAccounts,
                totalPages,
                newSearch: false,
                loading: false
            });
        } else {
            this.setState({
                bankAccounts: [],
                loading: false,
                totalPages: 0,
                newSearch: true,
            });
        }
        if (this.props.updatePage != null) {
            this.props.updatePage();
        }
    }

    getBankAccounts = async () => 
    {
        const json = await getBankAccountsByPerson(this.props.data);
        if (json !== undefined && json.bankAccounts !== undefined) {
            return json.bankAccounts.filter(bankAccount => bankAccount.Currency !== null);
        } else {
            return [];
        }
    }

    render() 
    {
        if (this.state.showBankAccount) 
        {
            return (
                <BankAccountScreen 
                    updatePage  = {this.updatePage} 
                    backLink    = {this.handleBackLink} 
                    isBreadcrum ={this.props.isBreadcrum}
                    data        = {this.state.selectedBankAccount} 
                    customerID  = {this.props.customerID}
                    personID    = {this.props.data}
                    subEntityType ={this.props.subEntityType}
                    updateBankAccount={this.props.updateBankAccountHandler} 
                    createBankAccount={this.props.createBankAccountHandler} 
                    isBeneficiary ={this.props.isBeneficiary}
                    addBreadcrumb={this.props.addBreadcrumb} 
                    removeBreadcrumb ={this.props.removeBreadcrumb}
                    hideDetail={this.props.hideDetail}
                    disableEditCompliance={this.props.disableEditCompliance}
                />
            );
        }

        return (
            <div>
                <h3 className="uk-heading-divider component-title">
                    {this.props.title !== undefined ? this.props.title : "Bank accounts"} &nbsp;
                    <button className="uk-button uk-button-green" disabled={this.props.disableEditCompliance} onClick={this.handleAddClick}>Add</button>
                </h3>
                <NewDynamicTable
                    hiddenHeaders       = {['Account ID']}
                    data                = {this.state.bankAccounts}
                    enableClick         = {true}
                    clickFunction       = {this.handleRowClick}
                    newSearch           = {this.state.newSearch}
                    useDeleteButton     = {true}
                    deleteFunction      = {this.handleDeleteClick}
                    usePaginator        = {true}
                    changePage          = {this.changePage}
                    numberOfPages       = {this.state.totalPages}
                    pageNumber          = {this.state.pages}
                    numberPerPage       = {getDefaultPagingNumberOfItems()}
                    enableSortByHeader  = {true}
                    useFilter           = {true}
                    filterFunction      = {this.getBankAccounts}
                    useExportButton     = {true}
                    tableName           = "bank_accounts"
                    useIcons            = {true}
                />
                <AlertBox 
                    open     = {this.state.showAlert} 
                    onClose  = {this.closeAlert} 
                    title    = {this.state.alertTitle} 
                    message  = {this.state.alertMessage} 
                    type     = "Ok" 
                    okClick  = {this.closeAlert}
                />
                <AlertBox 
                    open     = {this.state.showDeleteAlert} 
                    onClose  = {this.closeDeleteAlert} 
                    title    = "Warning" 
                    message  = "Are you sure you want to delete this bank account? This action can not be undone." 
                    type     = "Yes/No" 
                    yesClick = {this.yesDeleteAlert} 
                    noClick  = {this.closeDeleteAlert}
                />
                <AlertBox 
                    open     = {this.state.showSuccessDeleteAlert} 
                    onClose  = {this.closeDeleteAlert} 
                    title    = "Success" 
                    message  = "Bank account successfully removed." 
                    type     = "Ok" 
                    okClick  = {this.okSuccessAlert}
                />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}