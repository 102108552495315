import { Space, Table, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import '../../css/ant.css';
import TableFilter from './TableFilter';
import TheCSVButton from './TheCSVButton';
import { CheckOutlined, DeleteOutlined, EditOutlined, RollbackOutlined, InfoCircleOutlined }  from '@ant-design/icons';



export default class NewDynamicTable extends React.Component {
    
    state = {
        data            : this.props.data,
        listToShow      : [],
        filterScreen    : false,
        loading         : false,
        filtering       : false,
        filters         : {},
        pageNumber      : this.props.pageNumber,
        isAllChecked    : false,
    }

    componentDidMount = () => {
        let dataHelper = this.props.data
        if (this.props.formats !== undefined) {
            dataHelper.forEach((element) => {
                this.props.formats.forEach((values) => {
                    if (element[values.header] != null) {
                        if(element[values.formatCurrencyRate] != null)
                        {
                            element[values.header] = values.formatFunction(element[values.header], element[values.formatCurrency[0]],element[values.formatCurrency[1]]);
                        }else if(element[values.formatCurrency] != null)
                        {
                            element[values.header] = values.formatFunction(element[values.header], element[values.formatCurrency]);
                        }
                        else
                        {
                            if(values.formatCurrency != null)
                            {
                                element[values.header] = values.formatFunction(element[values.header], values.formatCurrency);
                            }
                            else
                            {
                                if(element[values.formatBuyCurrency] != null && element[values.formatSellCurrency]  != null)
                                    {
                                        element[values.header] = values.formatFunction(element[values.header], element[values.formatBuyCurrency] , element[values.formatSellCurrency] );
                                    }
                                    else
                                    {
                                        element[values.header] = values.formatFunction(element[values.header]);
                                    }
                            }
                        }
                        console.log(element)
                        //element[values.header] = element[values.formatFunction](element[values.header]);
                        
                    }
                })
            })
        }
        this.setState({ data: dataHelper });
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.data !== this.props.data){
            let dataHelper = this.props.data
            //debugger;
            if (this.props.formats !== undefined) {
                dataHelper.forEach((element) => {
                    this.props.formats.forEach((values) => {
                        if (element[values.header] != null) {
                            //debugger;
                            if(element[values.formatCurrency] != null)
                            {
                                element[values.header] = values.formatFunction(element[values.header], element[values.formatCurrency]);
                            }
                            else
                            {
                                if(values.formatCurrency != null)
                                {
                                    element[values.header] = values.formatFunction(element[values.header], values.formatCurrency);
                                }
                                else
                                {
                                    if(element[values.formatBuyCurrency] != null && element[values.formatSellCurrency]  != null)
                                    {
                                        element[values.header] = values.formatFunction(element[values.header], element[values.formatBuyCurrency] , element[values.formatSellCurrency] );
                                    }
                                    else
                                    {
                                        element[values.header] = values.formatFunction(element[values.header]);
                                    }
                                }
                            }
                        }
                    })
                })
            }
            this.setState({ data: dataHelper });
        }
    }
    
    getFilterHeaders = () => {
        var hiddenHeaders = [];
        var columns = [];
        if(this.props.data != null && this.props.data.length > 0) {
            var testObj = this.props.data[0];
            var headers = Object.keys(testObj);
            if(this.props.hiddenHeaders !== undefined){
                hiddenHeaders = this.props.hiddenHeaders;
            }

            headers.forEach((value) => {
                
                    var includesHiddenDollar = value.includes('$');
                    if (value !== 'TotalCount')
                    {
                        if (!includesHiddenDollar)
                        {
                            var isHidden = false;
                            for (let i = 0; i < hiddenHeaders.length; i++) {
                                if(value === hiddenHeaders[i])
                                {
                                    isHidden = true;
                                    break;
                                }
                            }

                            if (!isHidden){
                                columns.push(value);
                            }
                        }
                    }
            })
        }

        return columns;
    }
 
    getColumns = () => {
        var hiddenHeaders = [];
        var columns = [];
        if(this.props.data != null && this.props.data.length > 0) {
            var testObj = this.props.data[0];
            var headers = Object.keys(testObj);
            if(this.props.hiddenHeaders !== undefined){
                hiddenHeaders = this.props.hiddenHeaders;
            }

            headers.forEach((value) => {
                
                    var includesHiddenDollar = value.includes('$');
                    if (value !== 'TotalCount')
                    {
                        if (!includesHiddenDollar)
                        {
                            var isHidden = false;
                            for (let i = 0; i < hiddenHeaders.length; i++) {
                                if(value === hiddenHeaders[i])
                                {
                                    isHidden = true;
                                    break;
                                }
                            }

                            if (!isHidden){
                                if (value !== 'lookupdetails') {
                                    columns.push({
                                        title: value,
                                        dataIndex: value,
                                        key: value,
                                        sorter: (a, b) => { 
                                            let aValue = a[value];
                                            let bValue = b[value];
                                            if(!aValue){
                                                aValue='';
                                            }
                                            if(!bValue){
                                                bValue='';
                                            }
                                            if(typeof aValue === 'number'){
                                                return aValue - bValue;
                                            }
    
                                            return aValue.toString().localeCompare(bValue.toString());
                                        },
                                        sortDirections: ['descend', 'ascend'],
                                        align: this.props.align == null ? 'left' : this.props.align
                                    });
                                } else {
                                    columns.push({
                                        title: value,
                                        dataIndex: value,
                                        key: value,
                                        sorter: (a, b) => { 
                                            let aValue = a[value];
                                            let bValue = b[value];
                                            if(!aValue){
                                                aValue='';
                                            }
                                            if(!bValue){
                                                bValue='';
                                            }
                                            if(typeof aValue === 'number'){
                                                return aValue - bValue;
                                            }
    
                                            return aValue.toString().localeCompare(bValue.toString());
                                        },
                                        sortDirections: ['descend', 'ascend'],
                                        render: (text,record) => {
                                            let helper = [];
                                            if (Array.isArray(record.lookupdetails)) {
                                                //debugger
                                                record.lookupdetails.forEach((el) => {
                                                    helper.push(el.Description);
                                                });
                                            }
                                            return helper.join();
                                        }
                                    });
                                }
                                
                                
                            }
                        }
                    }
            })

            if(this.props.dynamicActions !== undefined || this.props.useDeleteButton){
                let links = [];
                //{ name: 'View', function: this.handleSearchClick, isIcon: true, icon:'fa fa-search' }
                columns.push({
                    title: 'Actions',
                    key: 'actions',
                    render: (text, record) => {
                        let links = [];
                        if(this.props.dynamicActions){
                            this.props.dynamicActions.forEach((dynAction) => {                                
                                if (dynAction.isIcon != null && dynAction.isIcon && dynAction.icon != null && dynAction.icon !== '') {
                                    if(this.props.useIcons != null && this.props.useIcons){
                                        links.push(<Tooltip title={dynAction.name}><a style={{cursor: 'pointer'}} onClick={(event) => {event.stopPropagation(); dynAction.function(record);}}>{dynAction.icon}</a></Tooltip>);
                                    }else{
                                        links.push(<a className={dynAction.icon} title={dynAction.name} style={{ cursor: 'pointer' }} onClick={(event) => { event.stopPropagation(); dynAction.function(record) }} />);
                                    }                                    
                                }
                                else {
                                    links.push(<a style={{ cursor: 'pointer' }} onClick={(event) => { event.stopPropagation(); dynAction.function(record) }}>{dynAction.name}</a>);
                                }
                            });
                        }
                        if(this.props.useDeleteButton){
                            if(this.props.useIcons){
                                    links.push(<Tooltip title="Delete"><a style={{cursor: 'pointer'}} onClick={(event) => {event.stopPropagation(); this.handleDeleteClick(record);}}><DeleteOutlined /></a></Tooltip>);
                            }
                            else{
                                links.push(<a style={{cursor: 'pointer'}} onClick={(event) => {event.stopPropagation(); this.handleDeleteClick(record);}}>Delete</a>);
                            }                            
                        }
                        return (
                            <Space size="middle">
                                {links}
                            </Space>
                        );
                    },
                });
            }
        }

        return columns;
    }

    handleDeleteClick = (obj) => {
        if (!JSON.parse(localStorage.getItem('HasComplianceAccess'))) {
            this.props.deleteFunction(obj);
        }
    }

    onFilterButtonClick = () =>{
        this.setState({filterScreen: true});
    }

    clearFilterButtonClick = () => {
        this.setState({filtering: false, filters: {}, data: this.props.data, numberOfPages: this.props.numberOfPages});
    }

    onCloseFilterScreen = () => {
        this.setState({filterScreen: false});
    }

    runFilter = (filters) => {
        var newData = [];
        this.setState({filterScreen: false, loading: true, filtering: true, filters: filters});
        this.props.filterFunction().then((rows) => {
            rows.forEach(element => {
                var isOkay = true;
                for(var i = 0; i < filters.length; i++){
                    if(filters[i].value !== '' && element[filters[i].header] !== undefined){
                        if(String(element[filters[i].header]).toLowerCase().includes(String(filters[i].value).toLowerCase()) && isOkay){
                            isOkay = true;
                        } else {
                            isOkay = false;
                        }
                    }
                }
                if(isOkay){
                    newData.push(element);
                }
            });
            this.setState({data: newData, loading: false, numberOfPages: Math.ceil(newData.length/this.props.numberPerPage), });
        });
    }
    
    render = () => {
        var showFilter = false;
        var showExport = false;
        var filterButton = [];
        var filterHeaders = [];
        var scrollStyle = {overflow: 'auto'};

        var id = "";
        
        if(this.props.id !== undefined){
            id = "-" + this.props.id;
        }

        if(this.props.useExportButton !== undefined){
            showExport = this.props.useExportButton;
        }

        if(this.props.useFilter !== undefined){
            showFilter = this.props.useFilter;
        }
        
        let dataSource = this.state.data;
        
        let columns = this.getColumns();
        filterHeaders = this.getFilterHeaders();

        if(showFilter)
        {
            if (!this.state.filtering) {
                filterButton.push(<i className="fa fa-fw fa-filter uk-align-right" key={"filter-icon" + id} onClick={this.onFilterButtonClick} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', marginBottom: '2px', marginLeft: '10px' }} />);
            } else {
                filterButton.push(<i className="fa fa-fw fa-ban uk-align-right" key={"filter-icon" + id} onClick={this.clearFilterButtonClick} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', marginBottom: '2px', marginLeft: '10px' }} />);
            }
        } else 
        {
            scrollStyle = {};
        }

        if(showExport){
            filterButton.push(<TheCSVButton board={this.props.tableName} data={this.state.data} useIcon={true}/>);
        }

        let rowSelection = null;

        if(this.props.useSelection) {
            rowSelection = {
                type: 'checkbox',
                onChange: this.props.onChangeSelection           
            };
    }

        return (
            <div style={scrollStyle}>
                <div className="uk-overflow-auto">
                    {filterButton}
                </div>
                <Table 
                    key = {(this.props.id) ? this.props.id : 'table'}
                    rowKey = {(record, rowIndex) => {
                        if(this.props.useSelection){
                            return record[this.props.selectionID];                        
                        }
                        else
                        {
                            return rowIndex;
                        }
                    }
                    }
                    className="uk-table-hover"
                    dataSource  = {[...dataSource]} 
                    rowSelection={rowSelection}
                    columns     = {columns} 
                    onRow       = {(record, rowIndex) => {
                        return {
                            onClick: event => {
                                if(this.props.clickFunction)
                                {
                                    this.props.clickFunction(record);
                                }
                            }
                        };
                    }}
                    size        = 'small'
                    scroll      = {{x: true}}
                />
                <TableFilter 
                    open={this.state.filterScreen}
                    onClose={this.onCloseFilterScreen}
                    headers={filterHeaders}
                    runFilter={this.runFilter}
                />
            </div>
        );
    }
}