import React from 'react';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import AlertBox from '../../../shared/AlertBox';
import { getBeneficiariesByCustomer, getBeneficiariesByID, deleteBeneficiary, getNewBeneficiaryEmail } from '../../../../networking/NetworkingBeneficiaries';
import {synchronizeBenesWithInterface} from '../../../../networking/NetworkingBankAccounts';
import BeneficiaryMaintenanceScreen from '../beneficiaries/BeneficiaryMaintenanceScreen';
import NewBeneficiaryMaintenance from '../newBeneficiary/NewBeneficiaryMaintenance';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import AddBeneficiaryScreen from '../addBeneficiary/AddBeneficiaryScreen';
import LoadingBox from '../../../shared/LoadingBox';
import DialogBeneficiaryInvitation from '../newBeneficiary/DialogBeneficiaryInvitation';

const itemsPerPage = getDefaultPagingNumberOfItems();
export default class BeneficiariesScreen extends React.Component {

    state = {
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        updateBene: false,
        addBene: false,
        newBene: false,
        customerID: '',
        beneID: '',
        selectedCustomer: [],
        customersBene: [],
        beneList: {},
        originList: {},
        type: '',
        totalpages: 0,
        currentPage: 1,
        newSearch: false,
        readyToDeleteBene: {},
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        email: '',
        isEmailValidated: false,
        isClearBene: false,
        isClearAdd: false,
        loading: false,
        openBeneInvitation: false
    }

    componentDidMount() {
        //GetForTable
        this.updatePage()
        
        if (this.props.show_list_bankAccount != null) {
            let show_list_bankAccount = this.props.show_list_bankAccount;
            if(show_list_bankAccount===true){
                this.handleAddClearClick();
            }
        }
    }

    transformBeneList(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push({
                'Beneficiary ID': list[i].BeneficiaryID,
                'Beneficiary Name': list[i].BeneficiaryName,
                'Country': list[i].CountryName,
                'Currency': list[i].Currency,
                'Risk Score': list[i].BeneficiaryRiskScore,
                'Beneficiary Status': list[i].BeneficiaryStatus
            });
        }
        return helper;
    }

    handleAddClick = () => {
        this.setState({ addBene: true, updateBene: false, type: '', beneID: '', beneList: {}, isClearBene: false, isClearAdd: false });
        this.props.createBeneficiaryHandler();
    }
    handleAddClearClick = () => {
        this.setState({ addBene: true, updateBene: false, type: '', beneID: '', beneList: {}, isClearBene: true, isClearAdd: true });
    }
    objAddBene = (objAddBene) => {
        this.setState({ addBene: false, updateBene: true, type: objAddBene.BeneficiaryType, beneID: objAddBene.BeneficiaryId, beneList: objAddBene, isClearBene: false, isClearAdd: false })
    }
    handleBeneInvitationClick = () => {
        this.setState({ openBeneInvitation: true })
    }

    //
    onSynchronizeBenesWithInterface = () => {
        this.setState({ loading: true });

        synchronizeBenesWithInterface(this.props.id, 'Currency Cloud').then(
            (response) => {
                console.log(response)
                switch (response.httpStatusCode) {
                    case 200:
                        let alertMessage = response.result.split("\n").map(function (item, idx) {
                            return (
                                <span key={idx}>
                                    {item}
                                    <br />
                                </span>
                            )
                        })
                        this.setState({
                            showAlert: true,
                            alertTitle: "Process Completed",
                            alertMessage: alertMessage,
                            loading: false
                        });
                        break;
                    default:
                        let errorMessage = response !== undefined ? response.errorMessage : "Something went wrong, Please try again later."
                        this.setState({
                            showAlert: true,
                            alertTitle: "Process Completed",
                            alertMessage: errorMessage,
                            loading: false
                        });
                }
            }
        ).finally(x => { this.setState({ loading: false }); })
    }
    //

    closeBeneInvitation = () => {
        this.setState({ openBeneInvitation: false })
    }
    handleRowClick(obj) {
        console.group("Beneficiary handleRowClick")
        console.log(obj);
        console.groupEnd();
        let title = `Beneficiary - ${obj['Beneficiary Name']} - ${obj['Beneficiary ID']}`;
        this.props.updateBeneficiaryHandler(title)
        getBeneficiariesByID(obj['Beneficiary ID'], 1).then(
            (json) => {
                console.log(json.beneficiary);
                this.setState({ beneList: json.beneficiary[0], type: json.beneficiary[0]['BeneficiaryType'], beneID: json.beneficiary[0]['BeneficiaryId'], updateBene: true, addBene: false });
            }
        );
    }
    handleDeleteClick(obj) {
        console.group("Beneficiary handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, readyToDeleteBene: obj });
        /*deleteBeneficiary(obj['Beneficiary ID']){}
        */
    }
    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteBene: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert() 
    {
        this.setState({ showDeleteAlert: false, loading: true });

        deleteBeneficiary(this.state.readyToDeleteBene['Beneficiary ID']).then(
            (deleteResponse) => {

                /*
                    ErrorMessage    : null
                    Message         : "cannot be removed"
                    httpStatusCode  : 503
                */

                let auxTitle = 'Error';
                let auxMessage = 'Something went wrong. Please try again later.';
                let deleted = false;

                if(deleteResponse !== undefined)
                {
                    switch (deleteResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            deleted = true;
                            break;
                        }
                        case 412:
                        case 503:
                        {
                            if(deleteResponse.Message !== undefined && deleteResponse.Message !== null && deleteResponse.Message !== '')
                            {
                                switch (deleteResponse.Message) 
                                {
                                    case 'cannot be removed':
                                    {
                                        auxMessage = 'This beneficiary cannot be deleted as it is linked to one or more entities in the system.';
                                        break;
                                    }
                                    default:
                                    {
                                        auxMessage = deleteResponse.Message;
                                        break;
                                    }
                                }
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }

                    if(deleted){
                        this.setState({ 
                            showSuccessDeleteAlert  : true, 
                            loading                 : false
                        }, () =>{
                            this.updatePage();
                        });
                    }else{
                        this.setState({
                            showAlert   : true,
                            alertTitle  : auxTitle,
                            alertMessage: auxMessage,
                            loading     : false
                        });
                    }
                }else
                {
                    this.setState({
                        showAlert   : true,
                        alertTitle  : auxTitle,
                        alertMessage: auxMessage,
                        loading     : false
                    });
                }
            }
        )
    }
    okSuccessAlert() {
        this.setState({ showSuccessDeleteAlert: false });
    }
    handleBackLink() {
        this.setState({ addBene: false, updateBene: false, newBene: false, isClearBene: false, isClearAdd: false });
    }
    changePage(value) {
        this.setState({ currentPage: value })
    }
    updatePage() {
        this.setState({loading: true},()=>{
        getBeneficiariesByCustomer(this.props.id, 0).then(
            (json) => {
                if (json.beneficiaries !== undefined) {
                    var helper = this.transformBeneList(json.beneficiaries);
                    var totalCount = json.totalCount;
                    var pages = Math.ceil(totalCount / itemsPerPage);
                    //bene
                    this.setState({
                        totalpages: pages, 
                        newSearch: true, 
                        customersBene: helper,
                        loading: false
                    });
                } else {
                    this.setState({ 
                        totalpages: 0,
                        customersBene: [],
                        newSearch: true,
                        loading: false 
                    })
                }
                console.log(this.state.customersBene);
            }
        )
        });
    }
    closeAlert() {
        this.setState({ showAlert: false, alertTitle: '' });
    }
    getAllBeneficiaries = async () => {
        var json = await getBeneficiariesByCustomer(this.props.id, 0);
        var helper = this.transformBeneList(json.beneficiaries);
        return helper;
    }

    beneTitle = (beneTitle) => {
        this.props.updateBeneficiaryHandler(beneTitle)
    }

    render() {
        if (this.state.addBene) {
            return (
                <AddBeneficiaryScreen
                    updateFunctionOrigin={this.updatePage.bind(this)}
                    backLink={this.props.backLink}
                    //backLink={this.handleBackLink.bind(this)}
                    selectedBene={this.objAddBene}
                    customerID={this.props.id}
                    customerPersonID={this.props.customerPersonID}
                    customerName={this.props.data}
                    isNewBene={this.handleAddClick}
                    isNewClearBene={this.handleAddClearClick}
                    isClearBene={this.state.isClearBene}
                    isClearAdd={this.state.isClearAdd}
                    beneTitle={this.beneTitle}
                    updateBeneficiaryHandler={this.props.updateBeneficiaryHandler}
                    createBeneficiaryHandler={this.props.createBeneficiaryHandler}
                />
            )
        }
        if (this.state.updateBene && !this.state.newBene) {
            return (
                <BeneficiaryMaintenanceScreen
                    updateFunctionOrigin={this.updatePage.bind(this)}
                    //backLink={this.props.backLink}
                    //backLink={this.handleBackLink.bind(this)}
                    beneID={this.state.beneID}
                    selectedBene={this.state.beneList}
                    type={this.state.type}
                    customerID={this.props.id}
                    customerPersonID={this.props.customerPersonID}
                    customerName={this.props.data}
                    isNewBene={this.handleAddClick}
                    isNewClearBene={this.handleAddClearClick}
                    isClearBene={this.state.isClearBene}
                    isClearAdd={this.state.isClearAdd}
                    updateBeneficiaryHandler={this.props.updateBeneficiaryHandler}
                    createBeneficiaryHandler={this.props.createBeneficiaryHandler}
                    updateBeneficiaryBankAccountHandler={this.props.updateBeneficiaryBankAccountHandler}
                    createBeneficiaryBankAccountHandler={this.props.createBeneficiaryBankAccountHandler}
                    addBreadcrumb={this.props.addBreadcrumb} 
                    removeBreadcrumb ={this.props.removeBreadcrumb}
                    hideDetail={this.props.hideDetail}
                />
            )
        }
        if (this.state.newBene && !this.state.updateBene) {
            return <NewBeneficiaryMaintenance />
        }

        return (
            <div>
                <div className="uk-grid">
                    <div className="uk-form-controls uk-width-3-5">
                        <h3 className="component-title">Beneficiaries&nbsp;
                            <button className="uk-button uk-button-green" onClick={this.handleAddClick}>Add</button>
                        </h3>
                    </div>
                    <div className="uk-width-1-5 component-title" style={{ textAlign: 'center' }}>
                        <button className="uk-button uk-button-green" onClick={this.handleBeneInvitationClick.bind(this)}>Beneficiary Invitation</button>
                    </div>
                    <div className="uk-width-1-5 component-title" style={{ textAlign: 'center' }}>                        
                        <button className="uk-button uk-button-green" onClick={this.onSynchronizeBenesWithInterface}>Synchronize With Currency Cloud</button>
                    </div>
                </div>
                <hr style={{ marginBottom: '5px', marginTop: '0px' }} />
                <div className="uk-grid">
                    <div style={{ width: '5%' }}></div>
                    <div className="uk-width-4-5">
                        <NewDynamicTable
                            key="beneficiary-screen"
                            hiddenHeaders={['Beneficiary ID']}
                            data={this.state.customersBene}
                            enableClick={true}
                            clickFunction={this.handleRowClick.bind(this)}
                            newSearch={this.state.newSearch}
                            useDeleteButton={true}
                            deleteFunction={this.handleDeleteClick.bind(this)}
                            enableSortByHeader={true}
                            usePaginator={true}
                            changePage={this.changePage.bind(this)}
                            numberOfPages={this.state.totalpages}
                            pageNumber={this.state.currentPage}
                            numberPerPage={getDefaultPagingNumberOfItems()}
                            useFilter={true}
                            filterFunction={this.getAllBeneficiaries}
                            useExportButton={true}
                            tableName="beneficiaries"
                            useIcons={true}
                        />
                    </div>
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Warning"
                    message='Are you sure you want to delete this Beneficiary? This action can not be undone.' type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Success" message="Beneficiary successfully removed." type="Ok" okClick={this.okSuccessAlert.bind(this)} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <LoadingBox loading={this.state.loading} />
                <DialogBeneficiaryInvitation PersonID={this.props.customer.PersonID} id={this.props.id} open={this.state.openBeneInvitation} close={this.closeBeneInvitation} />
            </div>
        );
    }
}