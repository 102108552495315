import jwt from 'jsonwebtoken';
import {genericCallWithBody, 
        genericGetWithParameters, 
        genericGetWithParametersNoEncrypted, 
        genericCallWithBodyAndResponseDecoded,
        genericGetWithBody} from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function saveDealHeader(objDealHeader){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Deals/Post';
    let response = await genericCallWithBody(method, apiName, objDealHeader);
    return response;
}
export async function updateDealHeader(objDealHeader){
    var method = '';
    var apiName = '';
    method = 'PUT';
    apiName = 'Deals/UpdateComments';
    let response = await genericCallWithBody(method, apiName, objDealHeader);
    return response;
}

//DealTypes/GetAllActiveDealTypes_min
export async function getDealTypes(){
    try {
        let params = {}
        let response = await genericGetWithParameters('DealTypes/GetAllActiveDealTypes_min',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function searchDeals(objCriteria){
    try {
        console.log(objCriteria);
        debugger
        let response = await genericGetWithBody('Deals/SearchDealsByJson',objCriteria);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealHeaderInfo(id){
    try {
        let params = {
            'dealHeaderId': id
        }

        let response = await genericGetWithParameters('Deals/GetDealHeaderInfo',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getFullDealHeader(dealHeaderID){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
            'userId': localStorage.getItem('UserID')
        }

        let response = await genericGetWithParameters('Deals/GetFullDealHeader',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidDealHeader(dealHeaderID, userID, updateToken){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
            'userID': localStorage.getItem('UserID'),
            'updateToken': updateToken,
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/Void',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidDealHeader2(dealHeaderID, userID, updateToken, voidOnPartnerConfirmed){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
            'userID': userID,
            'updateToken': updateToken,
            'voidOnPartnerConfirmed': voidOnPartnerConfirmed
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/Void',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function adjustDealHeader(dealHeaderID, userID, updateToken){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
            'userID': userID,
            'updateToken': updateToken,
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/Adjust',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getConfirmation(dealHeaderID){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
        }
        let response = await genericGetWithParametersNoEncrypted('Deals/GetConfirmation',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function generateConfirmationPDF(dealHeaderID, docType, language, reportTemplate) {
    try {
    var apiName = '';
    apiName = 'Deals/GenerateConfirmationPDF';

    let model = { 
        'dealHeaderID': dealHeaderID,
        'docType': docType,
        'language': language,
        'reportTemplate': reportTemplate
     }
     let response = await genericGetWithParametersNoEncrypted(apiName,model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDrawdownsByForward(forwardID, page, items){
    try {
        let params = {
            'forwardID': forwardID,
            'page': page,
            'items': items,
        };
        let response = await genericGetWithParameters('Deals/GetDrawdownsByForward',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutstandingForwardsMin(forwardID, customerID){
    try {
        let params = {
            'forwardID': forwardID,
            'customerID': customerID
        };
        let response = await genericGetWithParameters('Deals/GetOutstandingForwardsMin',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getOutstandingDealsByStatus(dealID, customerID, valueDate){
    try {
        let params = {
            'DealID'    : dealID,
            'CustomerID': customerID,
            'ValueDate' : valueDate
        };
        let response = await genericGetWithParameters('Deals/GetOutstandingDealsByStatus',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealsForPayments(customerID, currencyID, sourceTable, sourceID, typeRecord, page, items){
    try {
        let params = {
            'currencyID': currencyID,
            'customerID': customerID,
            'sourceTable': sourceTable,
            'sourceID': sourceID,
            'typeRecord': typeRecord,
            'page': page,
            'items': items,
        };
        let response = await genericGetWithParameters('Deals/GetDealsForPayments',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendDealReportByEmail(sendDealReportByEmailRequest){ 
    let method = 'POST';
    let apiName = 'Deals/SendDealReportByEmail';
    let response = await genericCallWithBody(method, apiName, sendDealReportByEmailRequest);
    return response;
}

export async function approvalBoard(approvalRequest){ 
    let method = 'PUT';
    let apiName = 'GenericBoard/Approve';
    let response = await genericCallWithBody(method, apiName, approvalRequest);
    return response;
}

export async function getComplianceInfo(Days, RiskFrom, RiskTo, CustomerId){
    try {
        let params = {
            'Days': Days,
            'RiskFrom': RiskFrom,
            'RiskTo': RiskTo,
            'CustomerId': CustomerId
        }
        let response = await genericGetWithParameters('Deals/GetComplianceInfo',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function checkCustomerCreditLimitMessate(DealHeaderID){
    try {
        let params = { 
            DealHeaderID: DealHeaderID
        }
        let response = await genericGetWithParametersNoEncrypted('Deals/CheckCustomerCreditLimitMessage', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealsForRollOver(dealId, customerId, fromDate, dealType){
    try {
        let params = {
            DealID: dealId, CustomerID: customerId, FromDate: fromDate, DealType: dealType
        }

        let response = await genericGetWithParameters('Deals/GetDealsForRollOver',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getSuspiciousDeals(customerID, dealHeaderId, searchBy, dateFrom, dateTo, risk, isSuspicious, noDateFilter, includeBanks)
{
    try {
        let params = {
            customerID      : customerID,
            dealHeaderId    : dealHeaderId,
            searchBy        : searchBy,
            dateFrom        : dateFrom,
            dateTo          : dateTo,
            risk            : risk,
            isSuspicious    : isSuspicious,
            noDateFilter    : noDateFilter,
            includeBanks    : includeBanks
        }

        let response = await genericGetWithParameters('Deals/GetSuspiciousDeals',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getSTPDeals(fromDate, toDate, searchBy, dealCreditStatus, page, items)
{
    try{
        let params = {
            FromDate: fromDate,
            ToDate: toDate,
            SearchBy: searchBy,
            DealCreditStatus: dealCreditStatus,
            Page: page,
            Items: items,
        }

        let response = await genericGetWithParameters('Deals/GetSTPDeals',params);
        return response;
    }  catch (error) {
        console.error(error);
    }  
}

export async function getMonitorDeals(date, lastUpdate, showVoid, dealOrigin, page, items)
{
    try{
        let params = {
            date: date,
            lastUpdate: lastUpdate,
            showVoid: showVoid,
            dealOrigin: dealOrigin,
            Page: page,
            Items: items,
        }

        let response = await genericGetWithParameters('Deals/GetDealMonitorByDate',params);
        return response;
    }  catch (error) {
        console.error(error);
    }  
}

export async function acceptCreditApproval(dealHeaderID, stpComments, installationID)
{
    try {
        var method = 'POST';
        var apiName = 'Deals/AcceptCreditApproval';
        let request = {
            'DealHeaderID': dealHeaderID,
            'STPComments': stpComments,
            'UpdatedBy': localStorage.getItem('UserID'),
            'InstallationID': installationID,
        }

        let response = await genericCallWithBody(method,apiName,request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function insertCoverDeal(objCoverDeal) {
    try {
        var method = 'POST';
        var apiName = 'Deals/InsertCoverDeal';
        let response = await genericCallWithBody(method, apiName, objCoverDeal);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function editDealHeaderCoverDeal(dealHeaderId, coverDealId, newCoverDealId) {
    try {
        var method = 'POST';
        var apiName = 'Deals/EditDealHeaderCoverDeal';
        let requestBody = {
            'DealHeaderId': dealHeaderId,
            'CoverDealId': coverDealId,
            'NewCoverDealId': newCoverDealId,
            'CreatedBy': localStorage.getItem('UserID'),
            'UpdatedBy': localStorage.getItem('UserID')
        }
        let response = await genericCallWithBody(method, apiName, requestBody);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendOutgoingFundReportByEmail(objOutgoingFund){ 
    let method = 'POST';
    let apiName = 'Deals/SendReportByEmail';
    let response = await genericCallWithBodyAndResponseDecoded(method, apiName, objOutgoingFund);
    return response;
}

export async function declineCreditApproval(dealHeaderID, stpComments, installationID)
{
    try {
        var method = 'POST';
        var apiName = 'Deals/DeclineCreditApproval';
        let request = {
            'DealHeaderID': dealHeaderID,
            'STPComments': stpComments,
            'UpdatedBy': localStorage.getItem('UserID'),
            'InstallationID': installationID,
        }

        let response = await genericCallWithBody(method,apiName,request);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function rollOverDeals(deals, userId, applicationDate) {
    var method = '';
    var apiName = '';

    method = 'POST';
    apiName = 'Deals/RollOverDeals';

    let model = { Deals: deals, UserID: userId, ApplicationDate: applicationDate }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export const getUncoveredDeals = async (customerID, traderID, page, items) => {
    try {
        const params = {
            'customerID': customerID,
            'traderId': traderID,
            'page': page,
            'items': items
        };
        const response = await genericGetWithParameters('Deals/GetCoverDeal', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getDealVersions = async (dealID) => {
    try {
        const params = {
            'dealID': dealID
        };
        const response = await genericGetWithParameters('Deals/GetDealHeaderVersionsById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getDealVersionInfo = async (version, dealID) => {
    try {
        const params = {
            'version': version,
            'dealID': dealID
        };
        const response = await genericGetWithParameters('Deals/GetDealHeaderInfoByIdAndVersion', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getDealVersionComparer = async (dealHeaderID, versionNumber1, versionNumber2) => {
    try {
        const params = {
            'dealHeaderID': dealHeaderID,
            'versionNumber1': versionNumber1,
            'versionNumber2': versionNumber2
        };
        const response = await genericGetWithParameters('Deals/GetDealVersionComparer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getLatest10DealsByCustomer = async (customerID) => {
    try {
        const params = {
            'customerID': customerID,
            'dealHeaderIDFrom': 0, 
            'dealHeaderIDTo': 0,
            'dealType': '', 
            'sellAmountFrom': 0, 
            'sellAmountTo': 0, 
            'sellCurrency': '', 
            'buyAmountFrom': 0, 
            'buyAmountTo': 0, 
            'buyCurrency': '',
            'valueDateFrom': null,
            'valueDateTo': null,
            'page': 1,
            'items': 10,
        }

        const response = await genericGetWithParameters('Deals/GetLatest10Deals', params)

        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getLatest10DealsByCustomerAndBuyCurrency = async (customerId, buyCurrency) => {
    try {
        const params = {
            'customerID': customerId,
            'dealHeaderIDFrom': 0,
            'dealHeaderIDTo': 0,
            'dealType': '',
            'sellAmountFrom': 0,
            'sellAmountTo': 0,
            'sellCurrency': '',
            'buyAmountFrom': 0,
            'buyAmountTo': 0,
            'buyCurrency': buyCurrency,
            'valueDateFrom': null,
            'valueDateTo': null,
            'page': 1,
            'items': 10,
        }
        const response = await genericGetWithParameters('Deals/GetLatest10Deals', params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getCoverDealsByPair = async (buyCurrency, sellCurrency) => {
    try {
        const params = {
            'buyCurrency': buyCurrency,
            'sellCurrency': sellCurrency, 
        }

        const response = await genericGetWithParameters('Deals/GetCoverDealsByPair', params)

        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function createMarginCalls(objMarginCall){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Deals/CreateMarginCalls';
    let response = await genericCallWithBody(method, apiName, objMarginCall);
    return response;
}

export async function validateImportDealsFields(request){ 
    try{
        let method = 'POST';
        let apiName = 'Deals/ValidateImportDealsFields';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, request);
        return response;
    } catch (error){
        console.error(error);
    }
}

export async function importDeals(request){ 
    try{
        let method = 'POST';
        let apiName = 'Deals/ImportDeals';
        let response = await genericCallWithBodyAndResponseDecoded(method, apiName, request);
        return response;
    } catch (error){
        console.error(error);
    }
}

export async function sameCurrencyDealRefund(request){
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'Deals/SameCurrencyDealRefund';
    let response = await genericCallWithBody(method, apiName, request);
    return response;
}

export async function getSameCurrencyDealRefunds(sourceTable, sourceID, currencyID){
    try {
        let params = {
            'sourceTable': sourceTable,
            'sourceID': sourceID,
            'currencyID': currencyID,
        }
        let response = await genericGetWithParameters('Deals/GetSameCurrencyDealRefunds',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function voidSameCurrencyDealRefund(dealHeaderID, userID, updateToken){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
            'userID': userID,
            'updateToken': updateToken,
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/VoidSameCurrencyDealRefund',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getInwardConfirmation(dealHeaderID){
    try {
        let params = {
            'dealHeaderID': dealHeaderID,
        }
        let response = await genericGetWithParametersNoEncrypted('Deals/GetInwardConfirmation',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function closeoutForward(dealHeaderID, userId, updateToken) {
    var method = '';
    var apiName = '';

    method = 'POST';
    apiName = 'Deals/CloseoutForward';

    let model = { DealHeaderID: dealHeaderID, UserID: userId, UpdateToken: updateToken }

    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function getCashProjection(){
    try {
        let params = {
            //'blottersID': ids,
        }
        let response = await genericGetWithParameters('CashProjection/GetCashProjections',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCashflowPositionByCurrency(currencyId){
    try {
        let params = {
            'CurrencyID': currencyId,
        }
        let response = await genericGetWithParameters('CashProjection/GetCashflowPositionByCurrency',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getCashflowPositionByNostroId(nostroAccountId){
    try {
        let params = {
            'NostroAccountID': nostroAccountId,
        }
        let response = await genericGetWithParameters('CashProjection/GetCashflowPositionByNostroId',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateEntityForReferral(dealHeaderID, outgoingFundID, userID){
    try {
        let params = {
            'DealHeaderID': dealHeaderID,
            'OutgoingFundID': outgoingFundID,
            'UpdatedBy': userID,
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/ValidateEntityForReferral',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateDealHeaderStatus(DealHeaderId, NewStatus){
    try {
        let params = {
            'DealHeaderId': DealHeaderId,
            'NewStatus': NewStatus,
            'UpdatedBy': localStorage.getItem('UserID')
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/UpdateDealHeaderStatus',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateForwardMaturityDate(model){
    
    try {
        var method = '';
        var apiName = '';
        method = 'PUT';
        apiName = 'Deals/UpdateForwardMaturityDate';
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateCustomerAndContactLimits(dealType, dealAmount, currencyID, customerID, contactID){
    try {
        let params = {
            'dealType': dealType,
            'dealAmount': dealAmount,
            'currencyID': currencyID,
            'customerID':customerID,
            'contactID':contactID
        }

        let response = await genericGetWithParametersNoEncrypted('Deals/ValidateCustomerAndContactLimits',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealBuybackAndValidation(dealId) {
    try {
        let params = {
            DealHeaderId: dealId,
        }
        let response = await genericGetWithParameters('Deals/GetDealBuybackAndValidation', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getDealBuyBackAllInfo(dealId) {
    try {
        let params = {
            DealHeaderId: dealId,
        }
        let response = await genericGetWithParameters('Deals/GetDealBuyBackAllInfo', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function confirmDealBuyBack(originalDealId, buyBackDealId, netAmount, netAmountCurrency, netFeeAmount, netFeeAmountCurrency,
    userId, comments) {
    try {
        let method = 'POST';
        let apiName = 'Deals/DealBuyBackConfirm';
        let model = {
            OriginalDealID: originalDealId,
            BuyBackDealID: buyBackDealId,
            NetAmount: netAmount,
            NetAmountCurrency: netAmountCurrency,
            NetFeeAmount: netFeeAmount,
            NetFeeAmountCurrency: netFeeAmountCurrency,
            userId: userId,
            Comments: comments
        }
        let response = await genericCallWithBody(method, apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getForwardWithLinkedSwapBalances(dealId) {
    try {
        let params = {
            dealHeaderId: dealId,
        }
        let response = await genericGetWithParameters('Deals/GetForwardWithLinkedSwapBalances', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendConfirmedByClientDealEmail(sendConfirmedByClientDealEmailRequest){ 
    let method = 'POST';
    let apiName = 'Deals/SendConfirmedByClientDealEmail';
    let response = await genericCallWithBody(method, apiName, sendConfirmedByClientDealEmailRequest);
    return response;
}

export async function sendInwardReportByEmail(sendInwardReportByEmailRequest){ 
    let method = 'POST';
    let apiName = 'Deals/SendInwardReportByEmail';
    let response = await genericCallWithBody(method, apiName, sendInwardReportByEmailRequest);
    return response;
}

export async function sendDealReportByEmailForTraderScreen(sendDealReportByEmailRequest){ 
    let method = 'POST';
    let apiName = 'Deals/SendDealReportByEmailForTradeScreen';
    let response = await genericCallWithBody(method, apiName, sendDealReportByEmailRequest);
    return response;
}